.ct-warm-hint {
  padding: 0 4vw;
  color: #999;
  font-size: 3.5vw;
  line-height: 1.8;

  &-header {
    color: #333;
    font-size: 3.75vw;
  }
}
