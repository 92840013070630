.ct-purchase {
  background-color: transparent;
  padding-bottom: 5vw;
  overflow: hidden;

  &-banner {
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 40vw;
  }

  .ct-card {
    height: 40vw;
    width: 92vw;
    margin: auto;
    color: #2e384e;
    background-image: url(../../assets/recharge/img_card_bg.png);
    box-shadow: 0px 0px 3vw 0.6vw rgba(49, 102, 208, 0.3);
    border-radius: 4vw;

    &-row {
      line-height: 1.9;
      justify-content: start;

      :first-child {
        margin-right: 4vw;
      }
    }
  }

  &-form {
    box-sizing: border-box;
    width: 92vw;
    margin: auto;
    text-align: center;

    .ct-input {
      background-color: #f6f6f6;
      border: 1px solid #d2d2d2;
      border-radius: 1.3vw;
      box-shadow: none;

      &::placeholder {
        color: #999;
      }
    }

    &-title {
      text-align: left;
      font-size: 4vw;
      color: #333;
      font-weight: 700;
      padding: 2vw 0 3vw;
    }
  }
}
