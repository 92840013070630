.ct-button {
  width: 80vw;
  height: 11vw;
  background-color: #4a85fb;
  box-shadow: 0px 1px 2vw 1px rgba(23, 77, 184, 0.3);
  border-radius: 5.3vw;
  border: 0;
  font-size: 4.2vw;
  color: #fefefe;
  margin: 4vw 0 6vw;
}
