.ct-input {
  width: 100%;
  box-sizing: border-box;
  height: 13.3vw;
  box-shadow: 0px 0px 3vw 0.6vw rgba(49, 102, 208, 0.3);
  // 解决苹果手机box-shadow失效问题
  -webkit-appearance: none;
  border-radius: 4vw;
  border: 0;
  margin-bottom: 4vw;
  font-size: 4vw;
  text-align: center;

  &::placeholder {
    color: #ccc;
  }

  &:disabled {
    opacity: 0.6;
    user-select: none;

  }
}
