.ct-city {
  width: 100%;
  height: 13.3vw;
  line-height: 13.3vw;
  margin-bottom: 4vw;
  box-sizing: border-box;
  font-size: 4vw;
  text-align: center;
  border: 1px solid #d2d2d2;
  border-radius: 1.3vw;
  background-color: #f6f6f6;
  user-select: none;

  &.placeholder {
    color: #999;
  }

  .am-icon-down {
    width: 4.5vw;
    height: 4.5vw;
    vertical-align: middle;
    margin-top: -1vw;
  }
}
