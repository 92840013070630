.index-container {
  min-height: 100vh;
  width: 100vw;
  background-color: #fff;
  &-banner {
    width: 100vw;
    height: 40vw;
  }
  &-scan {
    width: 40vw;
    height: 40vw; 
  }
  .ct-card {
    // 预销号
    &[data-bg='预销号'] {
      background-image: url(../../assets/index/img_home_card_red.png);
    }
    // 销号
    &[data-bg='销号'] {
      background-image: url(../../assets/index/img_home_card_grey.png);
    }

    .icon {
      width: 6.6vw;
      height: 6.6vw;
      margin-right: 2vw;
      vertical-align: middle;
    }

    .ct-card-row.header {
      font-size: 4.3vw;
      margin-bottom: 2.5vw;
      font-weight: 700;
    }
  }

  &-actions {
    &-row {
      margin: 0 auto 4vw;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 92vw;
      height: 17vw;
      background-color: #ffffff;
      box-shadow: 0px 0px 3.6vw 0px rgba(0, 42, 127, 0.15);
      border-radius: 1.6vw;
    }
    &-icon {
      width: 11vw;
      height: 11vw;
      margin-right: 2vw;
    }
    &-title {
      font-size: 4.4vw;
      min-width: 27vw;
    }
  }
}

.am-modal-content {
  .am-modal-header .am-modal-title {
    color: #333;
    font-size: 4vw;
  }

  .am-modal-body .am-modal-input-container:first-child {
    margin-top: 0;
  }
}
