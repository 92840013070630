@import '~antd-mobile/dist/antd-mobile.css';
body {
  background-color: #fff;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}
.am-picker-popup-item {
  color: #4a85fb;
}
