.ct-recharge-container {
  min-height: 100vh;
  width: 100vw;
  background-color: transparent;
  overflow: hidden;
  padding-bottom: 5vw;
  &-scan {
    width: 40vw;
    height: 40vw; 
  }
  .ct-card {
    .product-name {
      font-size: 4.2vw;
      margin-bottom: 2.5vw;
      justify-content: start;
      ::after {
        content: '';
      }
    }
    .ct-card-row {
      line-height: 2;
    }
    .icon {
      width: 6.6vw;
      height: 6.6vw;
      margin-right: 2vw;
      vertical-align: middle;
    }
  }

  &-banner {
    width: 100vw;
    height: 40vw;
  }

  &-form {
    box-sizing: border-box;
    width: 92vw;
    margin: auto;
    text-align: center;
  }
}
