.ct-card {
  padding: 10vw;
  height: 47.2vw;
  width: 100vw;
  margin: 0 auto;
  box-sizing: border-box;
  background: url(../../assets/index/img_home_card.png) no-repeat;
  background-size: 100% 100%;
  color: #fff;
  font-size: 4vw;

  &-row {
    display: flex;
    justify-content: space-between;
    line-height: 1.6;

    :first-child {
      &::after {
        content: '：';
      }
    }
  }
}
